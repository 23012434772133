<template lang="pug">
  div(v-on-clickaway="clearEmployees")
    div(v-for="ally in allies")
      input(type="hidden" name="ally_ids[]" :value="ally.id")

    .allies-list
      span.badge(v-for="ally in allies")
        a(@click="removeAlly(ally)") X
        span {{ ally.name }}

    div.search-wrapper.form-group
      input.form-control(
        type="text"
        v-model="query"
        :disabled="searching"
        :class="{disabled: searching}"
        placeholder="Search name or email"
        id="employee-search"
        @keydown.enter.prevent="onSubmit"
      )
      a.btn.btn-outline-primary(
        @click="onSubmit"
      ) Search
    ul#employee-search-results(v-if="!emptySearchResults")
      li(v-for="employee in filtedSearchResults")
        p
          span.employee-info
            span.employee-name {{ employee.name }}
            small {{ employee.email }}

          button.btn.btn-outline-primary(type="button" @click="addAlly(employee)") Add
    div(v-else)
      p No results match your search
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway';
const { mapActions, mapState } = createNamespacedHelpers('pursuits')

export default {
  id: 'search-allies',
  mixins: [ clickaway ],

  mounted () {
    let allies = JSON.parse(this.$el.parentElement.getAttribute("data-allies"))
    allies.forEach((ally) => this.loadAlly(ally))
  },

  data () {
    return {
      query: ""
    }
  },

  computed: {
    filtedSearchResults () {
      return this.employeeSearchResults.filter(a => this.allies.indexOf(a) == -1 )
    },
    resultsCount () {
      return this.filtedSearchResults.length
    },
    ...mapState([
      'employeeSearchResults',
      'allies',
      'searching',
      'emptySearchResults'
    ])
  },

  methods: {
    addAlly(employee) {
      this.allies.push(employee)
    },
    removeAlly(ally) {
      this.allies.splice(this.allies.indexOf(ally), 1)
    },
    onSubmit() {
      this.searchEmployees(this.query)
    },
    ...mapActions([
      'searchEmployees',
      'clearEmployees',
      'loadAlly'
    ])
  }
}
</script>

<style lang="scss" scoped>
  ul {
    list-style-type: none;
  }

  .allies-list {
    text-align: left;
    margin-bottom: 10px;
  }

  .badge {
    margin-right: 4px;
    a {
      margin-right: 4px;
    }
  }

  .search-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .employee-name {
    display: block;
  }

  .employee-info {
    text-align: left;
  }

  ul#employee-search-results {
    margin: 0;
    padding: 0;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
  }
</style>
