import Employee from '../services/employee'

export default {
  namespaced: true,
  state: {
    employeeSearchResults: [],
    allies: [],
    searching: false,
    emptySearchResults: false
  },
  mutations: {
    loadSearchResults (state, response) {
      state.employeeSearchResults = response.employees
      state.emptySearchResults = state.employeeSearchResults.length === 0
    },
    clearEmployees (state) {
      state.employeeSearchResults = []
      state.emptySearchResults = false
    },
    addAlly (state, response) {
      state.allies.push(response)
    },
    setSearching (state, value) {
      state.searching = value
    }
  },
  actions: {
    searchEmployees ({commit, dispatch}, query) {
      commit('setSearching', true)
      Employee.search(query)
        .then(response => {
          commit('loadSearchResults', response.data)
          commit('setSearching', false)
        })
    },
    loadAlly ({commit, dispatch}, employee) {
      commit('addAlly', employee)
    },
    clearEmployees ({commit}) {
      commit('clearEmployees')
    }
  }
}
