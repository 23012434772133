import Vue from 'vue'
import Vuex from 'vuex'
import pursuits from './pursuits'
import gifs from './gifs'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: { pursuits, gifs }
})
