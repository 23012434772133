//= require jquery
//= require jquery_ujs
import Vue from 'vue'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'

import EmployeeSelect from '../../../engines/pursuits/app/assets/javascripts/pursuits/components/EmployeeSelect'
import GifSearch from '../../../engines/pursuits/app/assets/javascripts/pursuits/components/GifSearch'
import ActivitiesForm from '../../../engines/pursuits/app/assets/javascripts/pursuits/components/ActivitiesForm'
import { i18n } from '../organization_management/config/translations.js'
import { store } from '../../../engines/pursuits/app/assets/javascripts/pursuits/store/index.js'

Vue.use(BootstrapVue)

document.addEventListener('DOMContentLoaded', () => {
  // configure translations
  i18n.setLocaleMessage('default', window.I18n)

  // configure axios
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  axios.defaults.headers.post['X-CSRF-Token'] = token

  const vueComponents = [
    EmployeeSelect,
    GifSearch,
    ActivitiesForm
  ]

  vueComponents.forEach(obj => {
    const appContainer = document.getElementById(obj.id)

    if(appContainer) {
      const el = appContainer.appendChild(document.createElement('app'))

      /* eslint-disable no-unused-vars */
      const app = new Vue({
        i18n,
        store,
        el,
        render: h => h(obj)
      })
      /* eslint-enable no-unused-vars */
    }
  })

})
