<template lang="pug">
  div
    .row
      .col-md-8.d-inline-flex
        a.btn.btn-primary(@click="addActivity") Add Activities
        span.help-block.padding-5-top.margin-10-left
         | Minimum 3 required*
      .col-md-4.padding-0-right.total-point-btn
        button.btn.btn-disabled#total-pursuit-points-text Total Everyday Action Points: 0
    ul
      li.list-pursuit-activities(v-for="(activity, index) in activities")
        input(type="hidden" :name="fieldName('id')" v-model="activity.id")
        div(v-if="activity.destroy")
          input(type="hidden" :name="fieldName('_destroy')" :value="true")
          .row.bg-gray-100
            .col-sm-6
              strong Removed
            .col-sm-6
              a.btn.btn-outline-primary.btn-sm.pull-right(@click="restoreActivity(index)") Restore?

        div(v-else )
          .form-group
            textarea.form-control(:name="fieldName('description')" v-model="activity.description")
            span.error-message(v-if="submit_status != 'draft' && pursuit_type != 'template' && activity.description == ''")
              | Description cannot be blank

          .row
            .col-md-4(style="width: 40.3%; display: inline-flex;")
              span(style="line-height: 35px;") Points on completion*:
              .form-group.margin-8-left
                select(style="width: 80px;").form-control(:name="fieldName('value')" v-model="activity.value" @change="updateValue()")
                  option(v-for="n in 10" v-bind:value="n") {{ n }}

            .col-md-4(style="display: inline-flex;")
              span(style="line-height: 35px;") Perform 
              .form-group.margin-8-left
                input(style="width: 80px;").form-control(type="number" :name="fieldName('number_of_times')" v-model="activity.number_of_times" min="1" @change="updateValue()")
              span.margin-8-left(style="line-height: 35px;") Times*

            .col-md-2(style="float: right; padding-right: 0px !important;")
              a.btn.btn-danger.btn-sm.pull-right(@click="removeActivity(index)") Remove

          .row
            .col-md-12
              span.error-message(v-if="submit_status != 'draft' && pursuit_type != 'template' && activity.value == null")
                | Value cannot be blank
    span.error-message(v-if="(submit_status != 'draft' && pursuit_type != 'template' && submit_status != '') && (activities.filter(activity => activity.destroy == null).length < 3)")
      | At least three activities should be added for an action.
    span.error-message#activities-error
</template>

<script>
export default {
  id: 'activities-form',

  mounted () {
    let activities = JSON.parse(this.$el.parentElement.getAttribute("data-activities"))
    this.submit_status = this.$el.parentElement.getAttribute("data-event")
    this.pursuit_type = this.$el.parentElement.getAttribute("data-pursuit-type")
    activities.forEach((activity) => this.activities.push(activity))
    activities.filter(activity => activity.value == null).forEach(activity => activity.value = 1)
    this.updateValue()
  },

  data () {
    return {
      activities: [],
      errors: [],
      total_value: 0,
      submit_status: '',
      pursuit_type: ''
    }
  },

  methods: {
    fieldName(attr) {
      return `pursuit[activities_attributes][][${attr}]`
    },

    addActivity() {
      this.activities.push({ description: '', value: 1, number_of_times: 1, id: null })
      this.updateValue()
    },

    removeActivity(index) {
      this.$set(this.activities[index], 'destroy', true)
      this.updateValue()
    },
    
    restoreActivity(index) {
      this.$delete(this.activities[index], 'destroy')
      this.updateValue()
    },

    updateValue(){
      this.total_value = this.activities.filter(ac => ac.destroy != true).reduce((acc, item) => acc + (item.value * item.number_of_times), 0)
      document.getElementById("pursuit_default_point_total").setAttribute("value", this.total_value)
      document.getElementById("total-pursuit-points-text").innerHTML="Total Everyday Action Points: "+this.kFormatter(parseInt(this.total_value))
    },

    kFormatter(number){
      var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
      var tier = Math.log10(Math.abs(number)) / 3 | 0;
      if(tier == 0) return number;
      var suffix = SI_SYMBOL[tier];
      var scale = Math.pow(10, tier * 3);
      var scaled = number / scale;
      return scaled.toFixed(1) + suffix;
    }
  }
}
</script>


<style lang="scss" scoped>
  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 20px;
    }
  }
  .error-message {
    color: red;
    margin-top: 10px;
  }
</style>
