import Gif from '../services/gif'

export default {
  namespaced: true,
  state: {
    gifSearchResults: [],
    persistedImage: null
  },
  mutations: {
    loadSearchResults (state, response) {
      state.gifSearchResults = response.data.map(gifObject => {
        return {
          id: gifObject.id,
          fixed_height_image: gifObject.images.fixed_height.url,
          original_image: gifObject.images.original.url
        }
      })
    }
  },
  actions: {
    searchGifs ({commit, dispatch}, query) {
      Gif.search(query)
        .then(response => {
          commit('loadSearchResults', response.data)
        })
    }
  }
}
