<template lang="pug">
  div
    input(type="hidden" :name="`${formContext}[image]`" :value="selectedGif.original_image" v-if="selectedGif != null")
    .form-group.search-box-large
      input(
        v-model="searchQuery"
        placeholder="search GIFs"
        @keydown.enter.prevent="debouncedSearch(searchQuery)")
      .search-box-large__button
        img(:src="searchIcon")

    ul#gif-list(v-if="selectedGif === null")
      li(v-for="searchResult in gifSearchResults")
        input(type="radio" :id="`search-result-${searchResult.id}`" :value="searchResult" v-model="selectedGif")
        label(:for="`search-result-${searchResult.id}`")
          img(v-bind:src="searchResult.fixed_height_image")
    .text-center(v-else)
      p
        img(v-bind:src="selectedGif.fixed_height_image")
      p
        a.btn.btn-danger(@click="selectedGif = null") Choose another?

</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash'
const { mapActions, mapState } = createNamespacedHelpers('gifs')

export default {
  id: 'gif-search',

  created () {
    this.debouncedSearch = debounce(this.searchGifs, 300)
  },

  mounted () {
    this.formContext = this.$el.parentElement.getAttribute("data-form-context")
    this.searchIcon = this.$el.parentElement.getAttribute("data-search-icon")
  },

  data () {
    return {
      searchQuery: '',
      selectedGif: null,
      formContext: '',
      searchIcon: ''
    }
  },

  computed: {
    ...mapState([
      'gifSearchResults'
    ])
  },

  methods: {
    gifUrl(searchResult) {
      return searchResult.images.fixed_height.url
    },

    ...mapActions([
      'searchGifs'
    ])
  },

  watch: {
    searchQuery (newVal, oldVal) {
      this.debouncedSearch(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-box-large {
    width: 100%;
  }

  #gif-list {
    display: flex;
    overflow-x: scroll;
    list-style-type: none;
    padding: 0;
  }

  input[type="radio"] {
    display: none;
  }
</style>
